import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";

import Loader from "@/components/common/loader";

import { getAffiliateBillingEvent, getAffiliateBillingEventVariables, getAffiliateBillingEvent_getAffiliateBillingEvent_billingEvent } from "../../graphql/__generated__/getAffiliateBillingEvent";
import { GET_AFFILIATE_BILLING_EVENT } from "../../graphql/GET_AFFILIATE_BILLING_EVENT";

import { HISTORY_REFERRAL_INVITATION_PAGE_SIZE } from "./constants";
import EmptyHistory from "./emptyHistory";
import ListHistory from "./listHistory";
import "./styles.scss";

const HistoryReferral = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [affiliateBillingData, setAffiliateBillingData] = useState<
  getAffiliateBillingEvent_getAffiliateBillingEvent_billingEvent[] | []>([]);

  const [getAffiliateBillingEventQuery, {
    data: affiliateBillingEventData,
    loading,
    error,
  }] = useLazyQuery<getAffiliateBillingEvent, getAffiliateBillingEventVariables>(GET_AFFILIATE_BILLING_EVENT);

  useEffect(() => {
    getAffiliateBillingEventQuery({
      variables: {
        limit: HISTORY_REFERRAL_INVITATION_PAGE_SIZE,
      },
    });
  }, [getAffiliateBillingEventQuery]);

  const onLoadMoreClick = () => {
    getAffiliateBillingEventQuery({
      variables: {
        limit: HISTORY_REFERRAL_INVITATION_PAGE_SIZE,
        page: currentPage,
      },
    });
  };

  useEffect(() => {
    if (affiliateBillingEventData?.getAffiliateBillingEvent.billingEvent && !loading && !error) {
      const data = affiliateBillingEventData.getAffiliateBillingEvent.billingEvent;

      setAffiliateBillingData((prevState: any) => {
        const newData = [...prevState, ...data];
        return newData.sort((a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      });
      setCurrentPage((prevCurrentPage) =>
        prevCurrentPage + 1);
    }
  }, [affiliateBillingEventData, error, loading]);

  const totalCount = affiliateBillingEventData?.getAffiliateBillingEvent.pagination.totalCount || 0;
  const totalPages = affiliateBillingEventData?.getAffiliateBillingEvent.pagination.totalPages || 0;
  const remainingCount = totalCount - (currentPage - 1) * HISTORY_REFERRAL_INVITATION_PAGE_SIZE;
  const showLoadMoreButton = currentPage <= totalPages;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="history-referral">
          <p className="history-referral__title">История приглашений</p>
          {affiliateBillingData.length ? (
            <ListHistory
              affiliateBillingEventData={affiliateBillingData}
              onLoadMoreClick={onLoadMoreClick}
              remainingCount={remainingCount}
              showLoadMoreButton={showLoadMoreButton}
              scrollToTop={scrollToTop}
            />
          ) : (
            <EmptyHistory scrollToTop={scrollToTop} />
          )}
        </div>
      )}
    </>
  );
};

export default HistoryReferral;
