import React from "react";

import { navigate } from "gatsby";

import { Button } from "@/components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

const NotAvailableBlock = () =>
  (
    <div className="unavailable-block">
      <Icon type={IconTypeEnum.Smiley} size={IconSizeEnum.Size32} viewBox={40} />
      <span className="unavailable-block__text">
        Вы пока не можете никого пригласить,
        <br />
        потому что не узнали нас лучше
      </span>
      <span className="unavailable-block__text description">
        Сделайте несколько звонков
        <br />
        на общую сумму от
        {" "}
        <span className="unavailable-block__fix-prace">10000 ₽,</span>
        <br />
        и вы сможете дарить минуты друзьям
      </span>
      <Button
        text="Выбрать эксперта"
        className="unavailable-block__button"
        onClick={() =>
          navigate("/catalog")}
      />
    </div>
  );

export default NotAvailableBlock;
